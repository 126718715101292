<template>
    <div>
      <div class="mybox">
        <el-button class="spbox" type="primary" @click="putvideo">视频面试</el-button>
      </div>
        
    </div>
</template>
<script>

export default {
    data(){
        return{
           id:"",
           t:""
        }
    },
    created(){
      this.id=this.$route.query.id
      this.t=this.$route.query.t
    },
    methods:{
    putvideo(){
      this.$router.push({path:"/company/video-call",query:{id:this.id,t:this.t}});
    }
  }
}
</script>
<style scoped>
.mybox{ padding-top: 40px;}
.spbox{ width:300px!important; height: 120px!important; font-size: 18px;}
</style>